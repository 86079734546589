import React from 'react'
import { graphql } from 'gatsby'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../lib/helpers'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import GraphQLErrorList from '../components/graphql-error-list'
import SEO from '../components/seo'
import Layout from '../containers/layout'

export const query = graphql`
  fragment SanityImage on SanityMainImage {
    crop {
      _key
      _type
      top
      bottom
      left
      right
    }
    hotspot {
      _key
      _type
      x
      y
      height
      width
    }
    asset {
      _id
    }
  }

  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    about: allSanityAbout {
      edges {
        node {
          _rawDescription(resolveReferences: {maxDepth: 10})
          _rawImage(resolveReferences: {maxDepth: 10})
          _rawFrontImage(resolveReferences: {maxDepth: 10})
        }
      }
    }
    book: allSanityBook {
      edges {
        node {
          _rawBackCover(resolveReferences: {maxDepth: 10})
          _rawDescription(resolveReferences: {maxDepth: 10})
          _rawFrontCover(resolveReferences: {maxDepth: 10})
          _rawSpine(resolveReferences: {maxDepth: 10})
          _rawSpreads(resolveReferences: {maxDepth: 10})
         
          purchaseUrls
          title
        }
      }
    }
    posts: allSanityPost(
      limit: 20
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
    ) {
      edges {
        node {
          id
          publishedAt
          mainImage {
            ...SanityImage
            alt
          }
          title
          _rawExcerpt
          _rawBody
          slug {
            current
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const { data, errors } = props

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }

  const site = (data || {}).site
  const postNodes = (data || {}).posts
    ? mapEdgesToNodes(data.posts)
      .filter(filterOutDocsWithoutSlugs)
      .filter(filterOutDocsPublishedInTheFuture)
    : []

  const about = (data || {}).about ? mapEdgesToNodes(data.about) : [];
  const book = (data || {}).book ? mapEdgesToNodes(data.book) : [];

  if (!site) {
    throw new Error(
      'Missing "Site settings". Open the studio at http://localhost:3333 and add some content to "Site settings" and restart the development server.'
    )
  }

  return (
    <Layout book={book} about={about}>
      <SEO
        title={site.title}
        description={site.description}
        keywords={site.keywords}
      />

      {postNodes && (
        <BlogPostPreviewList
          title='Latest blog posts'
          nodes={postNodes}
          browseMoreHref='/archive/'
          animate={true}
        />
      )}

    </Layout>
  )
}

export default IndexPage
