import { format } from 'date-fns'
import { Link } from 'gatsby'
import React, { useEffect, useRef, useState } from 'react'
import { buildImageObj, cn, getBlogUrl } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import PortableText from './portableText'

function BlogPostPreview(props) {
  const ref = useRef();
  // console.log(props)

  return (
    <Link
      ref={ref}
      state={{
        modal: true
      }}
      className={` w-64 block relative font-sans hover:opacity-50`}
      to={getBlogUrl(props.publishedAt, props.slug.current)}
    >
      <div className={`rte-text mb-6 fade-content`}>
        <div className={``}>{format(props.publishedAt, 'MM.DD.YYYY')}</div>
        <h3 className="italic">{props.title}</h3>
      </div>
      {/* <div>
    //   <div>
    //     {props.mainImage && props.mainImage.asset && (
    //       <img
    //         src={imageUrlFor(buildImageObj(props.mainImage))
    //           .width(600)
    //           .height(Math.floor((9 / 16) * 600))
    //           .auto('format')
    //           .url()}
    //         alt={props.mainImage.alt}
    //       />
    //     )}
    //   </div> 
    //   
    // </div> */}
    </Link>
  )
}

export default BlogPostPreview
