import { Link } from "gatsby";
import React, { useEffect, useState, useRef } from "react";
import BlogPostPreview from "./blog-post-preview";

function BlogPostPreviewGrid(props) {
  const [random, setRandom] = useState(false);
  // const [boxDims, setBoxDims] = useState([]);
  const itemEls = useRef({});
  const timer = useRef();

  const setRandomPos = () => {
    var boxes = [];
    var boxDims = [];
    var conflict = false;

    Object.keys(itemEls.current).map((item, i) => {
      var $this = itemEls.current[item];
      var fixLeft = Math.floor(Math.random() * (window.innerWidth - $this.offsetWidth - 10) + 10);
      var fixTop = Math.floor(
        Math.random() * (window.innerHeight - $this.offsetHeight - 200) + 100
      );
      var box = {
        top: parseInt(fixTop),
        left: parseInt(fixLeft),
        width: parseInt($this.getBoundingClientRect().width),
        height: parseInt($this.getBoundingClientRect().height),
      };
      for (var i = 0; i < boxDims.length; i++) {
        if (overlap(box, boxDims[i])) {
          conflict = true;
        }
      }
      boxDims.push(box);
    });

    if (conflict) {
      console.log("conflict found: rerun random position");
      setRandomPos();
    } else {
      Object.keys(itemEls.current).map((item, i) => {
        var $this = itemEls.current[item];
        $this.style.left = boxDims[i].left + "px";
        $this.style.top = boxDims[i].top + "px";
      });
      timer.current = setTimeout(setRandomPos, 4000);
    }
  };
  function overlap(box1, box2) {
    var x1 = box1.left;
    var y1 = box1.top;
    var h1 = box1.height;
    var w1 = box1.width;
    var b1 = y1 + h1;
    var r1 = x1 + w1;
    var x2 = box2.left;
    var y2 = box2.top;
    var h2 = box2.height;
    var w2 = box2.width;
    var b2 = y2 + h2;
    var r2 = x2 + w2;

    var buf = 24;

    if (b1 + buf < y2 || y1 > b2 + buf || r1 + buf < x2 || x1 > r2 + buf) return false;
    return true;
  }

  const onResize = () => {
    if (Object.keys(itemEls.current).length > 0) {
      if (window.innerWidth > 768) {
        setRandomPos();
        setRandom(true)
        console.log('set random to true')
      } else {
        setRandom(false)
        if(timer.current) {
          clearTimeout(timer.current);
        }
      }
    } else {
      setRandom(false)
    }
  };

  useEffect(() => {
    if (typeof window) {
      onResize();
      window.addEventListener("resize", onResize);
    }
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <div className={`w-full relative flex h-full my-auto`}>
      {/* {props.title && <h2 className={``}>{props.title}</h2>} */}
      <ul className={`relative ${random ? 'm-0' : 'mt-20 m-6'}`}>
        {props.nodes &&
          props.nodes.map((node, index) => {
            if (props.id) {
              if (node.id !== props.id)
                return (
                  <li key={node.id}>
                    <BlogPostPreview {...node} isInList animate={props.animate} />
                  </li>
                );
            } else {
              return (
                <li
                  id={node.slug.current}
                  ref={(element) => (itemEls.current[index] = element)}
                  className={`${random ? 'absolute' : ''} duration-1000 transition-all block hover:opacity-50`}
                  key={node.id}
                >
                  <BlogPostPreview {...node} isInList animate={props.animate} />
                </li>
              );
            }
          })}
      </ul>

    </div>
  );
}

BlogPostPreviewGrid.defaultProps = {
  title: "",
  nodes: [],
  browseMoreHref: "",
};

export default BlogPostPreviewGrid;
